import { useContext } from 'react';
// TODO fix active button: https://stackoverflow.com/questions/56972436/material-ui-button-active-style
import {
  alpha,
  Box,
  Button,
  List,
  ListItem,
  styled,
  Typography
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryIcon from '@mui/icons-material/History';
import HubIcon from '@mui/icons-material/Hub';
import LockIcon from '@mui/icons-material/Lock';
import PestControlIcon from '@mui/icons-material/PestControl';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { GiSpiderWeb } from 'react-icons/gi';
import Label from 'src/components/Label';
import { useFetchThreatTypeCounts } from 'src/lib/hooks';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(0)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 0px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const LinkButtonWithCounts = ({
  text,
  count
}: {
  text: string;
  count: number;
}) => (
  <>
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      marginRight={0.2}
    >
      <Typography>{text}</Typography>
      {count !== null && <Label color="primary">{count}</Label>}
    </Box>
  </>
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  const { data: threatCounts, isLoading } = useFetchThreatTypeCounts();

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  end
                  to="/dashboard"
                  startIcon={<DashboardIcon />}
                >
                  Dashboard
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/assets"
                  startIcon={<LockIcon />}
                >
                  Assets
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  end
                  to="/threats"
                  startIcon={<PestControlIcon />}
                  sx={{ paddingRight: 0.2 }}
                >
                  <LinkButtonWithCounts
                    text="Threats"
                    count={isLoading ? null : threatCounts.total}
                  />
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  sx={{ paddingRight: 0.2, paddingLeft: '25px !important' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/threats/cve"
                  startIcon={<ReportProblemIcon />}
                >
                  <LinkButtonWithCounts
                    text="Vulnerabilities"
                    count={isLoading ? null : threatCounts.vulnerabilities}
                  />
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  sx={{ paddingRight: 0.2, paddingLeft: '25px !important' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/threats/malware"
                  startIcon={<SecurityIcon />}
                >
                  <LinkButtonWithCounts
                    text="Malware"
                    count={isLoading ? null : threatCounts.malware}
                  />
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  sx={{ paddingRight: 0.2, paddingLeft: '25px !important' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/threats/brand"
                  startIcon={<BrandingWatermarkIcon />}
                >
                  <LinkButtonWithCounts
                    text="Brand protection"
                    count={isLoading ? null : threatCounts.brand}
                  />
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  sx={{ paddingRight: 0.2, paddingLeft: '25px !important' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/threats/infrastructure"
                  startIcon={<StorageIcon />}
                >
                  <LinkButtonWithCounts
                    text="Infrastructure"
                    count={isLoading ? null : threatCounts.infrastructure}
                  />
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  sx={{ paddingRight: 0.2, paddingLeft: '25px !important' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/threats/dark-web"
                  startIcon={<GiSpiderWeb />}
                >
                  <LinkButtonWithCounts
                    text="Dark web"
                    count={isLoading ? null : threatCounts.darkWeb}
                  />
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  sx={{ paddingRight: 0.2, paddingLeft: '25px !important' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/threats/information-leakage"
                  startIcon={<VpnKeyIcon />}
                >
                  <LinkButtonWithCounts
                    text="Information leakage"
                    count={isLoading ? null : threatCounts.informationLeakage}
                  />
                </Button>
              </ListItem>
              {/* <ListItem component="div">
                <Button
                  sx={{ paddingRight: 0.2, paddingLeft: '25px !important' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/scans"
                  startIcon={<WifiTetheringIcon />}
                >
                  HTTP Scans
                </Button>
              </ListItem> */}
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/history"
                  startIcon={<HistoryIcon />}
                >
                  History
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/graph"
                  startIcon={<HubIcon />}
                >
                  Graph{' '}
                  <Label sx={{ marginLeft: 1 }} color="secondary">
                    Beta
                  </Label>
                </Button>
              </ListItem>
              {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/intel"
                  startIcon={<TopicIcon />}
                >
                  Intel
                </Button>
              </ListItem> */}
              {/* <ListItem component="div">
                <Button
                  disableRipple
                  onClick={(e) => setAssetsOpen(!assetsOpen)}
                  startIcon={<BoltIcon />}
                  endIcon={assetsOpen ? <ExpandLess /> : <ExpandMore />}
                >
                  Services
                </Button>
              </ListItem>
              <Collapse in={assetsOpen} timeout="auto">
                <List component="div">
                  <ListItem>
                    <Button
                      sx={{ paddingLeft: '25px !important' }}
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/assets/overview"
                      startIcon={<AppsIcon />}
                    >
                      Overview
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      sx={{ paddingLeft: '45px !important' }}
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/malware"
                      startIcon={<CoronavirusIcon />}
                    >
                      Malware
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      sx={{ paddingLeft: '45px !important' }}
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/brand"
                      startIcon={<SecurityIcon />}
                    >
                      Brand
                    </Button>
                  </ListItem>
                </List>
              </Collapse> */}

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/settings"
                  startIcon={<SettingsIcon />}
                >
                  Settings
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
