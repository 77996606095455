import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Search } from 'src/lib/constants';

export function useSearch(): Search {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('search')) {
      setSearchQuery(searchParams.get('search') ?? '');
    }
  }, []);

  useEffect(() => {
    searchParams.set('search', searchQuery);
    setSearchParams(searchParams);
  }, [searchQuery]);

  return { searchQuery, setSearchQuery };
}
