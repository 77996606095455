import { formatDateAPISpec } from 'src/lib/helpers/datetime';
import {
  AssetBase,
  AssetDateTrendCount,
  AssetGraph,
  AssetLocation,
  AssetTypeCount,
  Domain,
  IPAddressAsset,
  Keyword
} from 'src/models/asset';
import { Port, TLSCertificate } from 'src/models/network';
import { PaginationResponse } from 'src/models/pagination';
import { APIClient, axiosPost } from '../APIService';

export const getAsset = (assetId: number) => {
  return APIClient.get<AssetBase>(`assets/all/${assetId}/`).then((r) => r.data);
};

export const postAsset = (asset: AssetBase) => {
  return axiosPost('assets/', asset);
};

export const getAssetDailyTrendCounts = (start_date, end_date) => {
  return APIClient.get<AssetDateTrendCount[]>(
    `assets/count/daily/from/${formatDateAPISpec(
      start_date
    )}/to/${formatDateAPISpec(end_date)}`
  ).then((r) => r.data);
};

export const getAssetsLocations = () => {
  return APIClient.get<AssetLocation[]>('assets/locations/').then(
    (r) => r.data
  );
};

export const getAssetTypeCounts = () => {
  return APIClient.get<AssetTypeCount>('assets/counts/types/').then(
    (r) => r.data
  );
};

export const getDomainDetails = (assetId: number) => {
  return APIClient.get<Domain>(`assets/domains/${assetId}/`).then(
    (r) => r.data
  );
};

export const getIPAddressDetails = (assetId: number) => {
  return APIClient.get<IPAddressAsset>(`assets/ip-addresses/${assetId}/`).then(
    (r) => r.data
  );
};

export const getKeywordDetails = (assetId: number) => {
  return APIClient.get<Keyword>(`assets/keywords/${assetId}/`).then(
    (r) => r.data
  );
};

export const getPorts = (assetId: number, params = {}) => {
  return APIClient.get<PaginationResponse<Port>>(
    `assets/ip-addresses/${assetId}/ports/`,
    {
      params: { ...params }
    }
  ).then((r) => r.data);
};

export const getAssets = (params = {}) => {
  return APIClient.get<PaginationResponse<AssetBase>>('assets/', {
    params
  }).then((r) => r.data.results);
};

export const getTLSCertificateDetails = (tlsCertificateId: number) => {
  return APIClient.get<TLSCertificate>(
    `assets/tls-certificates/${tlsCertificateId}/`
  ).then((r) => r.data);
};

export const getAssetGraphData = () => {
  return APIClient.get<AssetGraph>('assets/graph-data/').then((r) => r.data);
};
