import { useEffect, useState } from 'react';
import { Label } from 'src/models/asset';
import APIClient from '../../services/APIService';

interface useManageLabelsReturnValue {
  labels: Label[];
  loading: boolean;
  error: string;
  updateLabels: (assetId: number, labels: Label[]) => void;
}

export function useManageLabels(): useManageLabelsReturnValue {
  const [labels, setLabels] = useState<Label[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    setError('');
    APIClient.getLabels()
      .then((resp) => {
        const labelsArray = resp.data.map((r) => r as Label);
        setLabels(labelsArray);
      })
      .catch((err) => {
        setError('Error while trying to fetch labels');
      })
      .finally(() => setLoading(false));
  }, []);

  const updateLabels = (assetId: number, labels: Label[]) => {
    setLoading(true);
    APIClient.updateAssetLabels(assetId, labels).finally(() =>
      setLoading(false)
    );
  };

  return { labels, loading, error, updateLabels };
}
