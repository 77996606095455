import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useAssetsFiltering() {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // set default state from search params
    if (searchParams.get('assetType')) {
      setSelectedTypes(searchParams.get('assetType').split(','));
    }
  }, []);

  useEffect(() => {
    const query = {};

    query['assetType'] = selectedTypes.join(',');
    searchParams.set('assetType', query['assetType']);

    setSearchParams(searchParams, { replace: true });
  }, [selectedTypes]);

  return {
    selectedTypes,
    setSelectedTypes
  };
}
