import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Ordering, Pagination, Search, ThreatFilters } from 'src/lib/constants';
import {
  useOrdering,
  usePagination,
  useSearch,
  useThreatFiltering
} from 'src/lib/hooks';
import APIClient from 'src/lib/services/APIService';
import { Threat, threatStatus, threatType } from 'src/models/threat';
interface useFetchThreatsReturnValue {
  threats: Threat[];
  loading: boolean;
  error: string;
  pagination: Pagination;
  search: Search;
  ordering: Ordering;
  filtering: ThreatFilters;
}

/**
 * Hook used for fetching the threats from the API
 *
 * @returns threats - list of threats
 *          fetching - boolean flag to indicate if fetching is still in progress
 */
export function useFetchThreats(
  type: threatType = null,
  groupThreats: boolean = false
): useFetchThreatsReturnValue {
  const [loading, setLoading] = useState(false);
  const [threats, setThreats] = useState<Threat[]>([]);
  const [error, setError] = useState('');
  const pagination = usePagination();
  const search = useSearch();
  const ordering = useOrdering();
  const filtering = useThreatFiltering(groupThreats);

  const [searchParams, setSearchParams] = useSearchParams();

  const collectQueryParams = () => {
    const queryParams = {};

    // set params from searchParams
    queryParams['page'] = searchParams.get('page') || 1;
    queryParams['pageSize'] = searchParams.get('pageSize') || 10;
    queryParams['search'] = searchParams.get('search') || '';
    queryParams['ordering'] = searchParams.get('ordering') || '';
    queryParams['threatStatus'] =
      searchParams.get('threatStatus') || threatStatus.ACTIVE;
    queryParams['groupThreats'] =
      searchParams.get('groupThreats') || groupThreats.toString();

    queryParams['asset'] = searchParams.get('asset') || '';
    queryParams['severity'] = searchParams.get('severity') || '';
    queryParams['assetId'] = searchParams.get('assetId') || '';
    queryParams['threatType'] = searchParams.get('threatType') || '';

    if (type) {
      queryParams['threatType'] = type;
    }

    return queryParams;
  };

  useEffect(() => {
    setLoading(true);

    const threatQueryParams = collectQueryParams();

    APIClient.getAllThreats(threatQueryParams)
      .then((resp) => {
        const threatsArray = resp.data.results;
        pagination.setCount(resp.data.count);
        setThreats(threatsArray);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError('Failed to load threats');
      });
  }, [searchParams, type]);

  return {
    threats,
    loading,
    error,
    pagination,
    search,
    ordering,
    filtering
  };
}
