import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface useTabsValueReturnValue {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

/**
 * Hook used for managing tabs.
 * Provides current tab value and function for managing
 * @returns value - index of a current tab
 * @returns handleChange - function which changes the index of the tab
 */
export function useTabsValue(
  shouldUseURLParam = false,
  numberOfTabs = 0,
  replace = false
): useTabsValueReturnValue {
  const [value, setValue] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const tabId = searchParams.get('tabId');
    if (!shouldUseURLParam) return;
    const tab = parseInt(tabId);

    if (tab >= 0 && tab < numberOfTabs) {
      setValue(tab);
    }
  }, [searchParams]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (shouldUseURLParam) {
      setSearchParams({ tabId: `${newValue}` }, { replace });
    }
    setValue(newValue);
  };

  return { value, handleChange };
}
