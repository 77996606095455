import { Card } from '@mui/material';

export default function InformationCardContainer({
  sx,
  children
}: {
  sx?: any;
  children;
}) {
  return (
    <>
      <Card
        color="secondary"
        sx={{ borderRadius: 1, ...sx }}
        elevation={0}
        square={true}
      >
        {children}
      </Card>
    </>
  );
}
