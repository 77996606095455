/**
 * Converts date into %Y-%M-%d format which is needed for API
 * requests
 * @param d date which needs to be converted
 * @returns formatted date
 */
export function formatDateAPISpec(d: Date) {
  if (d === null || d === undefined) return null;
  return d.toISOString().split('T')[0];
}

/**
 * Returns hour and minute from date. For example
 * if the iso string of date is 1999-03-07T15:37:01.282Z
 * it will return '15:37'
 * @param d date which needs to be processed
 * @returns extracted hour and minute from date
 */
export function extractHourAndMinute(d: string) {
  return new Date(d).toISOString().split('T')[1].slice(0, 5);
}

/**
 * Take the difference between the dates and divide by milliseconds per day.
 * Round to nearest whole number to deal with DST.
 */
export function dateDiff(first: Date, second: Date) {
  const diffInMilliseconds = second.getTime() - first.getTime();
  return Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));
}
