import { useContext, useEffect } from 'react';
import {
  BreadcrumbContext,
  BreadcrumbItem
} from 'src/contexts/BreadcrumbContext';
import { UserContext } from 'src/contexts/UserContext';

type useBreadcrumbsReturnValue = {
  items: BreadcrumbItem[];
  isVisible: boolean;
};

/**
 *
 * @param name - name of the item which will be shown
 * in the breadcrumb menu
 * @param isRoot - if the component is root, whole breadcrumb menu
 * will be cleared when this item is accessed
 * @param readOnly - true if the component only needs to read the items, but
 * won't be part of the breadcrumb menu
 */
export function useBreadcrumbs(
  name: string,
  isRoot = false,
  readOnly = false
): useBreadcrumbsReturnValue {
  const context = useContext(BreadcrumbContext);
  const user = useContext(UserContext);

  useEffect(() => {
    if (!name) return;

    // make context visible
    context.setIsVisible(true);

    if (!readOnly) {
      const currentURL = window.location.href;

      const currentItem: BreadcrumbItem = {
        name: isRoot ? `${user.organization.name} - ${name}` : name,
        url: currentURL
      };

      if (isRoot) {
        context.addRootItem(currentItem);
      } else {
        context.removeAllAfterItem(currentItem);
      }
    }

    // make context not visible
    return () => context.setIsVisible(false);
  }, [name]);

  return { items: context.items, isVisible: context.isVisible };
}
