import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';

interface LabelProps {
  className?: string;
  color?:
    | 'primary'
    | 'black'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'vulnInfo'
    | 'vulnLow'
    | 'vulnMedium'
    | 'vulnHigh'
    | 'vulnCritical';
  children?: ReactNode;
  sx?: any;
}

const LabelWrapper = styled('span')(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      border-radius: ${theme.general.borderRadius};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      white-space: nowrap;

      &.clickable {
        cursor: pointer;
      }

      &.MuiLabel {

        &-primary {
          background-color: ${theme.colors.primary.lighter};
          color: ${theme.palette.primary.main}
        }

        &-black {
          background-color: ${theme.colors.alpha.black[100]};
          color: ${theme.colors.alpha.white[100]};
        }
        
        &-secondary {
          background-color: ${theme.colors.secondary.lighter};
          color: ${theme.palette.secondary.main}
        }
        
        &-success {
          background-color: ${theme.colors.success.lighter};
          color: ${theme.palette.success.main}
        }
        
        &-warning {
          background-color: ${theme.colors.warning.lighter};
          color: ${theme.palette.warning.main}
        }
              
        &-error {
          background-color: ${theme.colors.error.lighter};
          color: ${theme.palette.error.main}
        }
        
        &-info {
          background-color: #fff600;
          color: ${theme.palette.info.main}
        }
        &-vulnInfo {
          background-color: rgba(255, 246, 0, 0.8);
          color: #333;
          font-weight: bold;
        }
        &-vulnLow {
          background-color: rgba(255, 195, 2, 0.8);
          color: #333;
          font-weight: bold;
        }
        &-vulnMedium {
          background-color: rgba(255, 143, 0, 0.8);
          color: #333;
          font-weight: bold;
        }
        &-vulnHigh {
          background-color: rgba(255, 91, 0, 0.8);
          color: #333;
          font-weight: bold;
        }
        &-vulnCritical {
          background-color: rgba(255, 5, 5, 0.8);
          color: #333;
          font-weight: bold;
        }
      }
`
);

const Label: FC<LabelProps> = ({
  className,
  color = 'secondary',
  children,
  ...rest
}) => {
  return (
    <LabelWrapper className={`MuiLabel-${color} ${className}`} {...rest}>
      {children}
    </LabelWrapper>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'black',
    'secondary',
    'error',
    'warning',
    'success',
    'info',
    'vulnInfo',
    'vulnLow',
    'vulnMedium',
    'vulnHigh',
    'vulnCritical'
  ])
};

export default Label;
