import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getAssetTypeCounts } from 'src/lib/services/API/assets';
import { AssetTypeCount } from 'src/models/asset';

export function useFetchAssetCountsByType() {
  const [assetTypesCount, setAssetTypesCount] = useState<AssetTypeCount>({
    domain: 0,
    keyword: 0,
    ipAddress: 0
  });
  useQuery({
    queryKey: ['assetTypeCounts'],
    queryFn: getAssetTypeCounts,
    onSuccess: (data) => {
      setAssetTypesCount(data);
    }
  });

  return { assetTypesCount };
}
