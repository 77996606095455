import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        padding: 2px;
        height: 60px;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/dashboard">
      <img
        alt="Threat leap"
        width="100%"
        src="/static/images/logo/ThreatLeap_Logo_Text_Pfade_w_24.svg"
      />
    </LogoWrapper>
  );
}

export default Logo;
