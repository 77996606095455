import { useQuery } from '@tanstack/react-query';
import { formatDateAPISpec } from 'src/lib/helpers/datetime';
import { getHistoryAllScans } from 'src/lib/services/API/history';

export function useFetchAssetThreatHistoryScan(startDate, endDate) {
  return useQuery({
    queryKey: [
      'combinedHistoryScans',
      formatDateAPISpec(startDate),
      formatDateAPISpec(endDate)
    ],
    queryFn: () =>
      getHistoryAllScans({
        start: formatDateAPISpec(startDate),
        end: formatDateAPISpec(endDate)
      }),
    enabled: startDate !== null && endDate !== null
  });
}
