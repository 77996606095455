import { Paper, Typography, styled, useTheme } from '@mui/material';

export default function PlanExpired({ id }: { id?: string }) {
  const theme = useTheme();

  const PaperWrapper = styled(Paper)(
    ({ theme }) => `
        width: 100%;
        padding: ${theme.spacing(2)};
        background-color: ${theme.colors.error.dark};
        color: ${theme.colors.alpha.trueWhite[100]};
        position: flex;
        top: 0;
        border-radius: 0;
        z-index: 1000;
        right: 0;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          left: ${theme.sidebar.width};
          margin-left: ${theme.sidebar.width};
          width: auto;
      }
      `
  );

  return (
    <>
      <PaperWrapper id={id}>
        <Typography variant="h3" color={theme.colors.alpha.trueWhite[100]}>
          Your pricing plan has expired.
        </Typography>
        <Typography variant="h4">
          You are now in read-only mode, and you are not able to manage your
          assets.
          <br />
          All asset scans are paused until you upgrade your pricing plan.
        </Typography>
      </PaperWrapper>
    </>
  );
}
