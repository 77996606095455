import { PaginationResponse } from 'src/models/pagination';
import { Scan, ScanDetails, TLSCertificateScan } from 'src/models/scan';
import { APIClient } from '../APIService';

export const getScans = (params = {}) => {
  return APIClient.get<PaginationResponse<Scan>>('scans/url/', {
    params: { ...params }
  }).then((r) => r.data);
};

export const getScan = (scanId: number) => {
  return APIClient.get<ScanDetails>(`scans/url/${scanId}/`).then((r) => r.data);
};

export const getTLSCertificateScan = (scanId: number) => {
  return APIClient.get<TLSCertificateScan>(
    `scans/tls-certificate/${scanId}/`
  ).then((r) => r.data);
};
