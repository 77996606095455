import { useState } from 'react';

interface useModalReturnValue {
  isOpen: boolean;
  toggle: () => void;
}

/**
 * Hook used to control modal behavior.
 * @returns
 */
export function useModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return { isOpen, toggle };
}
