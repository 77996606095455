/**
 * Compares if two arrays have same elements, order is not important
 * @param a first array  to compare
 * @param b second array  to compare
 * @returns
 */
export const ignoreOrderCompareArrays = (a, b) => {
  if (a.length !== b.length) return false;
  const elements = new Set([...a, ...b]);
  for (const x of elements) {
    const count1 = a.filter((e) => e === x).length;
    const count2 = b.filter((e) => e === x).length;
    if (count1 !== count2) return false;
  }
  return true;
};

/**
 * Compares if two objects have the same keys and values
 * @param a first object to compare
 * @param b second object to compare
 * @returns
 */
export const compareObjects = (a, b) => {
  if (a === null || b === null) return false;

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) return false;
  for (const key of aKeys) {
    if (a[key] !== b[key]) return false;
  }
  return true;
};
