import { threatStatus, threatType } from 'src/models/threat';

export const THREAT_TYPES_TO_GROUP = [
  threatType.CVE,
  threatType.INFORMATION_LEAKAGE
];

export const ACCESS_TOKEN_AUTH = 'ACCESS_TOKEN_AUTH';
export const REFRESH_TOKEN_AUTH = 'REFRESH_TOKEN_AUTH';

export enum AssetTypes {
  DOMAIN = 'domain',
  IP_ADDRESS = 'ip_address',
  SUBDOMAIN = 'subdomain',
  PORT = 'port',
  IP_SUBNET = 'ip_subnet',
  KEYWORD = 'keyword',
  USERNAME = 'username'
}

export enum ThreatTypeRoute {
  CVE = 'cve',
  MALWARE = 'malware',
  BRAND = 'brand',
  PHISHING = 'brand/phishing',
  DOMAIN_TAKEOVER = 'brand/domain-takeover',
  INFRASTRUCTURE = 'infrastructure',
  DARK_WEB = 'dark-web',
  INFORMATION_LEAKAGE = 'information-leakage',
  SENSITIVE_DATA_LEAK = 'information-leakage/sensitive-data-leak',
  BREACH = 'information-leakage/breach',
  POTENTIAL_MALWARE_DISTRIBUTION = 'malware/distribution',
  INSECURE_PORT = 'insecure-port',
  TLS_CERTIFICATE = 'tls-certificate',
  DOMAIN_EXPIRY = 'domain-expiry'
}

export interface LabelValuePair {
  label: string;
  value: any;
}
export interface ThreatFilters {
  assets: string[];
  assetsSearch: string;
  setAssetsSearch: (query: string) => void;
  selectedAssets: string[];
  setSelectedAssets: (assets: string[]) => void;
  selectedTypes: string[];
  setSelectedTypes: (types: string[]) => void;
  selectedSeverities: string[];
  setSelectedSeverities: (severities: string[]) => void;
  selectedThreatStatus: string;
  setSelectedThreatStatus: (threatStatus: string) => void;
  selectedGroupThreats: boolean;
  setSelectedGroupThreats: (groupThreats: boolean) => void;
}

export interface Pagination {
  page: number;
  pageSize: number;
  count: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setCount?: (pageSize: number) => void;
}

export interface Search {
  searchQuery: string;
  setSearchQuery: (searchQuery) => void;
}

export interface QueryParams {
  page?: number;
  pageSize?: number;
  search?: string;
  ordering?: string;
}

export interface AssetQueryParams extends QueryParams {
  processingIgnored?: boolean;
  assetType?: string[];
}

export interface ThreatQueryParams extends QueryParams {
  severity?: string[];
  assetId?: number;
  threatType?: string[];
  threatStatus?: threatStatus;
}

export interface Ordering {
  orderingQuery: string;
  isActive: (name: string) => boolean;
  handleColumnClick: (name: string) => void;
  getDirection: (name: string) => 'asc' | 'desc';
}

export interface Selection {
  selected: number[];
  selectedObjects: any[];
  handleSelectAllClick: (e) => void;
  handleSelectClick: (object: any) => void;
  isSelected: (id: number) => boolean;
}

export const calendarMonths = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12'
};
