import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Popover,
  Typography,
  lighten
} from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LightModeIcon from '@mui/icons-material/LightMode';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';
import { UserContext } from 'src/contexts/UserContext';
import { useCustomNavigation } from 'src/lib/hooks';
import StorageService from 'src/lib/services/StorageService';
import { ThemeContext } from 'src/theme/ThemeProvider';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const themeContext = useContext(ThemeContext);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSignOut = (): void => {
    StorageService.removeAuthToken();
    navigate(0);
  };

  const navigation = useCustomNavigation();

  return (
    <>
      <UserContext.Consumer>
        {(user) => (
          <>
            <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
              <Avatar variant="rounded" alt={user.firstName} src={''} />
              <Hidden mdDown>
                <UserBoxText>
                  <UserBoxLabel variant="body1">
                    {user.firstName} {user.lastName}
                  </UserBoxLabel>
                  <UserBoxDescription variant="body2">
                    Regular user
                  </UserBoxDescription>
                </UserBoxText>
              </Hidden>
              <Hidden smDown>
                <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
              </Hidden>
            </UserBoxButton>
            <Popover
              anchorEl={ref.current}
              onClose={handleClose}
              open={isOpen}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuUserBox sx={{ minWidth: 210 }} display="flex">
                <Avatar variant="rounded" alt={user.firstName} src={''} />
                <UserBoxText>
                  <UserBoxLabel variant="body1">
                    {user.firstName} {user.lastName}
                  </UserBoxLabel>
                  <UserBoxDescription variant="body2">
                    Regular user
                  </UserBoxDescription>
                </UserBoxText>
              </MenuUserBox>
              <Divider sx={{ mb: 0 }} />
              <Box sx={{ m: 1 }}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={navigation.navigateToSettings}
                >
                  <SettingsIcon sx={{ mr: 1 }} />
                  Settings
                </Button>
                <Button color="primary" fullWidth onClick={handleSignOut}>
                  <LockOpenTwoToneIcon sx={{ mr: 1 }} />
                  Sign out
                </Button>
                <Box
                  sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                  }}
                >
                  <IconButton onClick={() => themeContext('PureLightTheme')}>
                    <LightModeIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => themeContext('NebulaFighterTheme')}
                  >
                    <DarkModeIcon />
                  </IconButton>
                </Box>
              </Box>
            </Popover>
          </>
        )}
      </UserContext.Consumer>
    </>
  );
}

export default HeaderUserbox;
