import { AuthToken } from 'src/models/user';
import { ACCESS_TOKEN_AUTH, REFRESH_TOKEN_AUTH } from 'src/lib/constants';

const StorageService = {
  async setAuthToken(token: AuthToken) {
    window.localStorage.setItem(ACCESS_TOKEN_AUTH, token.accessToken);
    window.localStorage.setItem(REFRESH_TOKEN_AUTH, token.refreshToken);
  },

  removeAuthToken() {
    window.localStorage.removeItem(ACCESS_TOKEN_AUTH);
    window.localStorage.removeItem(REFRESH_TOKEN_AUTH);
  },

  getAuthToken(): AuthToken {
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN_AUTH);
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_AUTH);

    return { accessToken, refreshToken };
  },

  tokenExists(): boolean {
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN_AUTH);
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_AUTH);

    return !!accessToken && !!refreshToken;
  }
};

export default StorageService;
