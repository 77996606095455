import { lazy } from 'react';
import LazyLoader from 'src/components/LazyLoader';

const Assets = LazyLoader(lazy(() => import('src/content/Assets')));
const IPAddressDetails = LazyLoader(
  lazy(() => import('src/content/NetworkAssets/IPAddressDetails'))
);
const DomainDetails = LazyLoader(
  lazy(() => import('src/content/NetworkAssets/DomainDetails'))
);
const KeywordDetails = LazyLoader(
  lazy(() => import('src/content/Brand/KeywordDetails'))
);

const TLSCertificateDetails = LazyLoader(
  lazy(() => import('src/content/NetworkAssets/TLSCertificate'))
);

export const assetRoutes = [
  {
    path: 'assets',
    element: <Assets />
  },
  {
    path: 'assets/domain/:domainId',
    element: <DomainDetails />
  },
  {
    path: 'assets/ip/:ipAddressId',
    element: <IPAddressDetails />
  },
  {
    path: 'assets/keyword/:keywordId',
    element: <KeywordDetails />
  },
  {
    path: 'assets/tls/:tlsCertificateId',
    element: <TLSCertificateDetails />
  }
];
