import { useState } from 'react';
import { Selection } from '../../constants';

/**
 * Hook used for handling checkbox selection of data
 * @param data
 * @returns
 */
export function useCheckboxSelection(data: any[]): Selection {
  const [selected, setSelected] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedObjects([...selectedObjects, ...data]);

      const newSelected = data.map((n) => n.id);
      setSelected(Array.from(new Set([...selected, ...newSelected])));
      return;
    }

    setSelected([]);
    setSelectedObjects([]);
  };

  const handleSelectClick = (object: any) => {
    const id = object.id;
    const selectedIndex = selected.indexOf(id);

    if (selectedIndex === -1) {
      // object is not selected
      setSelectedObjects([...selectedObjects, object]);
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((obj) => obj !== id));
      setSelectedObjects(selectedObjects.filter((obj) => obj.id !== id));
    }
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return {
    selected,
    selectedObjects,
    handleSelectAllClick,
    handleSelectClick,
    isSelected
  };
}
