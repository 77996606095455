import { FC, useState, createContext } from 'react';

export type BreadcrumbItem = {
  url: string;
  name: string;
};

type BreadcrumbContext = {
  items: BreadcrumbItem[];
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  addItem: (item: BreadcrumbItem) => void;
  addRootItem: (item: BreadcrumbItem) => void;
  removeAllAfterItem: (item: BreadcrumbItem) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BreadcrumbContext = createContext<BreadcrumbContext>(
  {} as BreadcrumbContext
);

export const BreadcrumbProvider: FC = ({ children }) => {
  const [items, setItems] = useState<BreadcrumbItem[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const addRootItem = (item: BreadcrumbItem) => setItems([item]);

  const addItem = (item: BreadcrumbItem) => {
    const newItems = [...items, item];
    setItems(newItems);
  };

  /**
   * Removes all elements after found item, including the found item.
   * @param item
   * @returns
   */
  const removeAllAfterItem = (item: BreadcrumbItem) => {
    const ix = items.findIndex(
      (it) => it.name === item.name && it.url === item.url
    );
    if (ix == -1) {
      // item is not found, we should add it
      addItem(item);
      return;
    }

    const newItems = [...items];
    //removes all elements after found index
    newItems.length = ix + 1;

    setItems(newItems);
  };

  return (
    <BreadcrumbContext.Provider
      value={{
        items,
        addRootItem,
        addItem,
        removeAllAfterItem,
        isVisible,
        setIsVisible
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};
