import { Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const LazyLoader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

export default LazyLoader;
