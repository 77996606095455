import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Ordering, Pagination, Search } from 'src/lib/constants';
import { useOrdering, usePagination, useSearch } from 'src/lib/hooks';
import { AssetBase } from 'src/models/asset';
import APIClient from '../../services/APIService';

interface useFetchAssetsReturnValue {
  assets: AssetBase[];
  loading: boolean;
  error: string;
  pagination: Pagination;
  search: Search;
  ordering: Ordering;
}

/**
 * Hook used for fetching domain assets from the API
 */

export function useFetchAssets(
  monitoredAssets: boolean = true
): useFetchAssetsReturnValue {
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<any>([]);
  const [error, setError] = useState<string>('');
  const pagination = usePagination();
  const search = useSearch();
  const ordering = useOrdering();

  const [searchParams, setSearchParams] = useSearchParams();

  const collectQueryParams = () => {
    const queryParams = {};

    // set params from searchParams

    queryParams['page'] = searchParams.get('page') || 1;
    queryParams['pageSize'] = searchParams.get('pageSize') || 10;
    queryParams['search'] = searchParams.get('search') || '';
    queryParams['ordering'] = searchParams.get('ordering') || '';
    queryParams['assetType'] = searchParams.get('assetType') || '';

    return queryParams;
  };

  useEffect(() => {
    setLoading(true);
    const queryParams = collectQueryParams();

    APIClient.getAssets({
      ...queryParams,
      processingIgnored: !monitoredAssets
    })
      .then((resp) => {
        const assetsArray = resp.data.results;
        pagination.setCount(resp.data.count);
        setAssets(assetsArray);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError('Failed to load assets');
      });
  }, [monitoredAssets, searchParams]);

  return { assets, loading, error, pagination, search, ordering };
}
