import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getHistoryTotalCounts } from 'src/lib/services/API/history';
import { HistoricTotalCounts } from 'src/models/history';

interface returnValue {
  historyTotalCounts: HistoricTotalCounts;
  isLoading: boolean;
}
export function useFetchHistoricTotalCounts(): returnValue {
  const [historyTotalCounts, setHistoryTotalCounts] =
    useState<HistoricTotalCounts>({
      totalThreats: 0,
      totalThreatsResolved: 0,
      totalThreatsIgnored: 0,
      totalAssetsMonitored: 0,
      totalAssetsScanned: 0,
      totalAssetsSuggested: 0
    });

  const { isLoading } = useQuery({
    queryKey: ['historicTotalCounts'],
    queryFn: getHistoryTotalCounts,
    onSuccess: (data) => {
      setHistoryTotalCounts(data);
    }
  });

  return {
    historyTotalCounts,
    isLoading
  };
}
