import { Box, Button, Card, Container, Typography } from '@mui/material';
import React from 'react';

export class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container maxWidth="sm">
          <Box
            display="flex"
            justifyContent="center"
            py={5}
            alignItems="center"
          >
            <img
              alt="Threat leap"
              height={160}
              src="/static/images/logo/ThreatLeap_Logo_Text_Pfade_dark_24.svg"
            />
          </Box>
          <Card sx={{ mb: 10, borderRadius: 2 }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              m={2}
              flexDirection="column"
            >
              <Typography variant="h3" mb={2}>
                An error occurred
              </Typography>
              <Typography variant="body1">
                While you were performing the action, an error occurred, to try
                again please refresh the page, or go to the dashboard
              </Typography>

              <Button
                onClick={() => (window.location.href = '/dashboard')}
                sx={{ marginTop: 5 }}
                variant="contained"
              >
                Go to dashboard
              </Button>
            </Box>
          </Card>
        </Container>
      );
    }

    return this.props.children;
  }
}
