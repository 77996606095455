import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Pagination } from 'src/lib/constants';
import { compareObjects } from 'src/lib/helpers/comparison';

export function usePagination(): Pagination {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);

  const [prevSearchParams, setPrevSearchParams] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    try {
      const paramPage = parseInt(searchParams.get('page'));
      if (Number.isFinite(paramPage)) {
        setPage(paramPage);
      }

      const paramPageSize = parseInt(searchParams.get('pageSize'));
      if (Number.isFinite(paramPageSize)) {
        setPageSize(paramPageSize);
      }
    } catch {
      // do not set page parameters from arguments if value is not int
    }
  }, []);

  useEffect(() => {
    // sets page to 1 if search params changed
    const copyOfCurrentSearchParams = {};
    searchParams.forEach((value, key) => {
      copyOfCurrentSearchParams[key] = value;
    });

    delete copyOfCurrentSearchParams['page'];
    delete copyOfCurrentSearchParams['pageSize'];

    if (prevSearchParams === null) {
      // do not set page to 1 if previous search params were not set
      setPrevSearchParams(copyOfCurrentSearchParams);
      return;
    }

    if (!compareObjects(copyOfCurrentSearchParams, prevSearchParams)) {
      setPage(1);
      setPrevSearchParams(copyOfCurrentSearchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());

    setSearchParams(searchParams);
  }, [page, pageSize]);

  return { page, pageSize, count, setPage, setPageSize, setCount };
}
