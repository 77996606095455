import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Ordering } from '../../constants';

type Order = 'asc' | 'desc';
type Columns = {
  [name: string]: Order;
};

export function useOrdering(): Ordering {
  const [orderingQuery, setOrderingQuery] = useState('');
  const [columns, setColumns] = useState<Columns>({} as Columns);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let query = '';
    Object.keys(columns).forEach((key) => {
      const order = columns[key];
      let field = key;

      if (order === null) return;
      if (order === 'desc') field = '-' + field; // desc ordering

      // we have to add comma after the field so that the next field can be included
      query += field + ',';
    });

    setOrderingQuery(query);
    searchParams.set('ordering', query);
    setSearchParams(searchParams);
  }, [columns]);

  const isActive = (columnName: string) => {
    if (!(columnName in columns)) return false;

    return columns[columnName] !== null;
  };

  const getDirection = (columnName: string): 'asc' | 'desc' => {
    let direction = columns[columnName];
    if (direction === null) return 'asc';

    return direction;
  };

  /**
   * Changes state of the column sort in a cycle:
   * null -> asc -> desc -> null -> ...
   * @param columnName name of the column
   */
  const handleColumnClick = (columnName: string) => {
    const newColumns = { ...columns };

    const state = columns[columnName];
    if (state === undefined || state === null) {
      newColumns[columnName] = 'asc';
    } else if (state === 'asc') {
      newColumns[columnName] = 'desc';
    } else {
      newColumns[columnName] = null;
    }
    setColumns(newColumns);
  };

  return { orderingQuery, isActive, handleColumnClick, getDirection };
}
