import { formatDateAPISpec } from 'src/lib/helpers/datetime';
import { PaginationResponse } from 'src/models/pagination';
import {
  CVEThreatDetails,
  DarkWebThreatDetails,
  DomainExpiryThreatDetails,
  DomainTakeoverThreatDetails,
  InsecurePortThreatDetails,
  MalwareThreatDetails,
  PhishingThreatDetails,
  PotentialMalwareDistribution,
  TLSCertificateThreatDetails,
  Threat,
  ThreatCountsBySeverity,
  ThreatCountsBySeverityTrend,
  ThreatCountsByType,
  ThreatDateTrendCount
} from 'src/models/threat';
import {
  BreachThreat,
  SensitiveDataLeakThreatDetails
} from 'src/models/threat/privacy';
import { APIClient } from '../APIService';
export const getThreatTypeCounts = () => {
  return APIClient.get<ThreatCountsByType>('threats/counts/types/').then(
    (r) => r.data
  );
};

export const getThreatSeverityCounts = () => {
  return APIClient.get<ThreatCountsBySeverity>('threats/counts/severity/').then(
    (r) => r.data
  );
};

export const getThreatSeverityTrendCounts = (start_date, end_date) => {
  return APIClient.get<ThreatCountsBySeverityTrend>(
    `threats/counts/severity/trend/from/${formatDateAPISpec(
      start_date
    )}/to/${formatDateAPISpec(end_date)}`
  ).then((r) => r.data);
};

export const getThreatDailyTrendCounts = (start_date, end_date) => {
  return APIClient.get<ThreatDateTrendCount[]>(
    `threats/count/daily/from/${formatDateAPISpec(
      start_date
    )}/to/${formatDateAPISpec(end_date)}`
  ).then((r) => r.data);
};

export const getThreatTrendPrediction = (start_date, end_date) => {
  return APIClient.get<ThreatDateTrendCount[]>(`threats/predictions/`).then(
    (r) => r.data
  );
};

export const getThreats = (params = {}) => {
  return APIClient.get<PaginationResponse<Threat>>('threats/', {
    params
  }).then((r) => r.data.results);
};

export const getCVEThreat = (threatId: number, params = {}) => {
  return APIClient.get<CVEThreatDetails>(`threats/cve/${threatId}`, {
    params
  }).then((r) => r.data);
};

export const getDarkWebThreat = (threatId: number, params = {}) => {
  return APIClient.get<DarkWebThreatDetails>(`threats/dark-web/${threatId}`, {
    params
  }).then((r) => r.data);
};

export const getDomainTakeoverThreat = (threatId: number, params = {}) => {
  return APIClient.get<DomainTakeoverThreatDetails>(
    `threats/domain-takeover/${threatId}`,
    {
      params
    }
  ).then((r) => r.data);
};

export const getDomainExpiryThreat = (threatId: number, params = {}) => {
  return APIClient.get<DomainExpiryThreatDetails>(
    `threats/domain-expiry/${threatId}`,
    {
      params
    }
  ).then((r) => r.data);
};

export const getInsecurePortThreat = (threatId: number, params = {}) => {
  return APIClient.get<InsecurePortThreatDetails>(
    `threats/insecure-ports/${threatId}`,
    {
      params
    }
  ).then((r) => r.data);
};

export const getMalwareThreatDetails = (threatId: number, params = {}) => {
  return APIClient.get<MalwareThreatDetails>(`threats/malware/${threatId}`, {
    params
  }).then((r) => r.data);
};

export const getPotentialMalwareDistributionThreat = (
  threatId: number,
  params = {}
) => {
  return APIClient.get<PotentialMalwareDistribution>(
    `/threats/malware-potential-distribution/${threatId}`,
    {
      params
    }
  ).then((r) => r.data);
};

export const getPhishingThreatDetails = (threatId: number, params = {}) => {
  return APIClient.get<PhishingThreatDetails>(`/threats/phishing/${threatId}`, {
    params
  }).then((r) => r.data);
};

export const getBreachThreat = (threatId: number, params = {}) => {
  return APIClient.get<BreachThreat>(`/threats/breaches/${threatId}`, {
    params
  }).then((r) => r.data);
};
export const getSensitiveDataLeakThreat = (threatId: number, params = {}) => {
  return APIClient.get<SensitiveDataLeakThreatDetails>(
    `/threats/sensitive-data-leak/${threatId}`,
    {
      params
    }
  ).then((r) => r.data);
};

export const getTLSCertificateThreat = (threatId: number, params = {}) => {
  return APIClient.get<TLSCertificateThreatDetails>(
    `/threats/tls-certificates/${threatId}`,
    {
      params
    }
  ).then((r) => r.data);
};
