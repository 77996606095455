import { AssetBase } from '../asset';
import { User } from '../user';

export enum threatType {
  CVE = 'CVE',
  MALWARE = 'Malware',
  BRAND = 'Brand',
  INFRASTRUCTURE = 'Infrastructure',
  DARK_WEB = 'Dark web',
  INFORMATION_LEAKAGE = 'Information leakage'
}

export enum threatSubtype {
  POTENTIAL_MALWARE_DISTRIBUTION = 'Potential malware distribution',
  TLS_CONFIGURATION = 'TLS configuration',
  TLS_EXPIRATION = 'TLS expiration',
  PORT_EXPOSURE = 'Port exposure',
  NEWLY_OPENED_PORT = 'Newly opened port',
  DOMAIN_TAKEOVER = 'Domain takeover',
  PHISHING = 'Phishing',
  BREACH = 'Breach',
  SENSITIVE_DATA_LEAK = 'Sensitive data leak',
  DOMAIN_EXPIRY = 'Domain expiry'
}

export enum threatStatus {
  ACTIVE = 'active',
  RESOLVED = 'resolved',
  IGNORED = 'ignored'
}

export interface Threat {
  id: number;
  severity: string;
  title: string;
  summary: string;
  threatStatus: threatStatus;
  dbCreatedAt: string;
  dbUpdatedAt: string;
  lastStatusChangeAt: string;
  lastStatusChangeBy: User;
  asset: AssetBase;
  threatType: threatType;
  threatSubtype: threatSubtype;
}

export interface ThreatDetails extends Threat {
  timelineUpdates: string[];
  threatsOnSameAsset: Threat[];
  assetsWithSameThreat: Threat[];
}
