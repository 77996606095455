import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import InformationCardContainer from 'src/components/InformationCardContainer';
import Loader from 'src/components/Loader';
import SearchBar from 'src/components/SearchBar';
import { Pagination } from 'src/lib/constants';
import { useCustomNavigation, usePagination, useSearch } from 'src/lib/hooks';
import { getScans } from 'src/lib/services/API/scans';
import { PaginationResponse } from 'src/models/pagination';
import { Scan } from 'src/models/scan';

export default function Scans() {
  const pagination = usePagination();
  const { searchQuery, setSearchQuery } = useSearch();

  const { data, isLoading } = useQuery({
    queryKey: ['scans', pagination.page, pagination.pageSize, searchQuery],
    queryFn: () =>
      getScans({
        page: pagination.page,
        pageSize: pagination.pageSize,
        search: searchQuery
      }),
    onSuccess: (data) => pagination.setCount(data.count)
  });

  return (
    <>
      <Box p={4} width="100%">
        <InformationCardContainer>
          <Box p={2}>
            <SearchBar
              placeholder="Search scanned assets"
              handleChange={setSearchQuery}
              value={searchQuery}
            />
          </Box>
          {isLoading ? (
            <Loader />
          ) : (
            <ScansTable pagination={pagination} data={data} />
          )}
        </InformationCardContainer>
      </Box>
    </>
  );
}
function ScansTable({
  pagination,
  data
}: {
  pagination: Pagination;
  data: PaginationResponse<Scan>;
}) {
  const navigation = useCustomNavigation();

  return (
    <>
      <TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        component="div"
        count={pagination.count}
        rowsPerPage={pagination.pageSize}
        page={pagination.page - 1}
        onPageChange={(event, newPage) => pagination.setPage(newPage + 1)}
        onRowsPerPageChange={(event) => {
          pagination.setPageSize(parseInt(event.target.value, 10));
          pagination.setPage(1);
        }}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Asset</TableCell>
              <TableCell align="center">Scan Date</TableCell>
              <TableCell align="center">Files with threats</TableCell>
              <TableCell align="center">Files scanned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.results.length > 0 &&
              data.results.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ ':hover': { cursor: 'pointer' } }}
                  hover
                  onClick={() => navigation.navigateToURLScan(item.id)}
                >
                  <TableCell align="left">{item.asset}</TableCell>
                  <TableCell align="center">
                    {new Date(item.scanDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">{item.threatsFound}</TableCell>
                  <TableCell align="center">{item.filesFound}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        component="div"
        count={pagination.count}
        rowsPerPage={pagination.pageSize}
        page={pagination.page - 1}
        onPageChange={(event, newPage) => pagination.setPage(newPage + 1)}
        onRowsPerPageChange={(event) => {
          pagination.setPageSize(parseInt(event.target.value, 10));
          pagination.setPage(1);
        }}
      />
    </>
  );
}
