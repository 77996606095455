import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ThreatFilters } from 'src/lib/constants';
import APIClient from 'src/lib/services/APIService';
import { threatStatus } from 'src/models/threat';

export function useThreatFiltering(
  defaultGroupThreats: boolean = false
): ThreatFilters {
  const [assets, setAssets] = useState<string[]>([]);
  const [assetsSearch, setAssetsSearch] = useState<string>('');

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [selectedSeverities, setSelectedSeverities] = useState<string[]>([]);
  const [selectedThreatStatus, setSelectedThreatStatus] = useState<string>(
    threatStatus.ACTIVE
  );
  const [selectedGroupThreats, setSelectedGroupThreats] =
    useState<boolean>(defaultGroupThreats);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // set default state from search params
    if (searchParams.get('asset')) {
      setSelectedAssets(searchParams.get('asset').split(','));
    }

    if (searchParams.get('severity')) {
      setSelectedSeverities(searchParams.get('severity').split(','));
    }

    if (searchParams.get('threatType')) {
      setSelectedTypes(searchParams.get('threatType').split(','));
    }

    if (searchParams.get('threatStatus')) {
      setSelectedThreatStatus(searchParams.get('threatStatus'));
    }

    if (searchParams.get('groupThreats')) {
      setSelectedGroupThreats(
        searchParams.get('groupThreats').toLowerCase() === 'true'
      );
    }
  }, []);

  useEffect(() => {
    const query = {};

    query['asset'] = selectedAssets.join(',');
    searchParams.set('asset', query['asset']);

    query['severity'] = selectedSeverities.join(',');
    searchParams.set('severity', query['severity']);

    query['threatType'] = selectedTypes.join(',');
    searchParams.set('threatType', query['threatType']);

    query['threatStatus'] = selectedThreatStatus.toString();
    searchParams.set('threatStatus', query['threatStatus']);

    query['groupThreats'] = selectedGroupThreats.toString();
    searchParams.set('groupThreats', query['groupThreats']);

    setSearchParams(searchParams, { replace: true });
  }, [
    selectedAssets,
    selectedTypes,
    selectedSeverities,
    selectedThreatStatus,
    selectedGroupThreats
  ]);

  useEffect(() => {
    APIClient.getThreatFilters('assets', assetsSearch).then((resp) =>
      setAssets(resp.data)
    );
  }, [assetsSearch]);

  return {
    assets,
    selectedAssets,
    assetsSearch,
    setAssetsSearch,
    setSelectedAssets,
    selectedTypes,
    setSelectedTypes,
    selectedSeverities,
    setSelectedSeverities,
    selectedThreatStatus,
    setSelectedThreatStatus,
    selectedGroupThreats,
    setSelectedGroupThreats
  };
}
