import { useRoutes } from 'react-router-dom';
import { commonRoutes } from './commonRoutes';
import Loader from 'src/components/Loader';
import StorageService from 'src/lib/services/StorageService';
import { protectedRoutes } from './protectedRoutes';
import { useFetchUser } from 'src/lib/hooks';
import { UserContext } from 'src/contexts/UserContext';

export const AppRoutes = () => {
  const tokenExists = StorageService.tokenExists();
  const { user, loading, error } = useFetchUser(tokenExists);

  //   TODO show only routes based on subscription
  const routes =
    !loading && Object.keys(user).length !== 0 && !error ? protectedRoutes : [];

  const element = useRoutes([...protectedRoutes, ...commonRoutes]);

  return (
    <>
      <UserContext.Provider value={user}>
        {loading ? <Loader /> : element}
      </UserContext.Provider>
    </>
  );
};
