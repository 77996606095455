import { lazy } from 'react';
import LazyLoader from 'src/components/LazyLoader';
import { ThreatTypeRoute } from 'src/lib/constants';

const Threats = LazyLoader(lazy(() => import('src/content/Threats')));
const CVEThreatsDetails = LazyLoader(
  lazy(() => import('src/content/Threats/Details/CVEDetails'))
);
const MalwareThreatsDetails = LazyLoader(
  lazy(() => import('src/content/Threats/Details/Malware/MalwareDetails'))
);
const PotentialThreatDistributionDetails = LazyLoader(
  lazy(
    () =>
      import('src/content/Threats/Details/Malware/PotentialDistributionDetails')
  )
);
const PhishingThreatDetails = LazyLoader(
  lazy(() => import('src/content/Threats/Details/Brand/PhishingDetails'))
);
const InsecurePortThreatDetails = LazyLoader(
  lazy(() => import('src/content/Threats/Details/InsecurePortThreatDetails'))
);
const DarkWebThreatsDetails = LazyLoader(
  lazy(() => import('src/content/Threats/Details/DarkWebDetails'))
);
const BreachThreatsDetails = LazyLoader(
  lazy(() => import('src/content/Threats/Details/BreachDetails'))
);
const SensitiveDataLeakDetails = LazyLoader(
  lazy(
    () => import('src/content/Threats/Details/Privacy/SensitiveDataLeakDetails')
  )
);
const DomainTakeoverDetails = LazyLoader(
  lazy(() => import('src/content/Threats/Details/Brand/DomainTakeoverDetails'))
);
const TLSCertificateThreatDetails = LazyLoader(
  lazy(() => import('src/content/Threats/Details/TLSCertificateThreatDetails'))
);
const DomainExpiryThreatDetails = LazyLoader(
  lazy(() => import('src/content/Threats/Details/DomainExpiryDetails'))
);

const CVEThreats = LazyLoader(
  lazy(() => import('src/content/Threats/ThreatTypes/CVEThreats'))
);
const MalwareThreats = LazyLoader(
  lazy(() => import('src/content/Threats/ThreatTypes/MalwareThreats'))
);
const BrandProtectionThreats = LazyLoader(
  lazy(() => import('src/content/Threats/ThreatTypes/BrandThreats'))
);
const InfrastructureThreats = LazyLoader(
  lazy(() => import('src/content/Threats/ThreatTypes/InfrastructureThreats'))
);
const DarkWebThreats = LazyLoader(
  lazy(() => import('src/content/Threats/ThreatTypes/DarkWebThreats'))
);
const InformationLeakageThreats = LazyLoader(
  lazy(() => import('src/content/Threats/ThreatTypes/PrivacyThreats'))
);

export const threatsRoutes = [
  {
    path: 'threats',
    element: <Threats />
  },
  {
    path: `threats/${ThreatTypeRoute.CVE}/`,
    element: <CVEThreats />
  },
  {
    path: `threats/${ThreatTypeRoute.CVE}/:threatId`,
    element: <CVEThreatsDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.MALWARE}`,
    element: <MalwareThreats />
  },
  {
    path: `threats/${ThreatTypeRoute.MALWARE}/:threatId`,
    element: <MalwareThreatsDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.POTENTIAL_MALWARE_DISTRIBUTION}/:threatId`,
    element: <PotentialThreatDistributionDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.BRAND}`,
    element: <BrandProtectionThreats />
  },
  {
    path: `threats/${ThreatTypeRoute.PHISHING}/:threatId`,
    element: <PhishingThreatDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.INFRASTRUCTURE}`,
    element: <InfrastructureThreats />
  },
  {
    path: `threats/${ThreatTypeRoute.INSECURE_PORT}/:threatId`,
    element: <InsecurePortThreatDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.DOMAIN_EXPIRY}/:threatId`,
    element: <DomainExpiryThreatDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.DARK_WEB}`,
    element: <DarkWebThreats />
  },
  {
    path: `threats/${ThreatTypeRoute.DARK_WEB}/:threatId`,
    element: <DarkWebThreatsDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.INFORMATION_LEAKAGE}`,
    element: <InformationLeakageThreats />
  },
  {
    path: `threats/${ThreatTypeRoute.BREACH}/:threatId`,
    element: <BreachThreatsDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.SENSITIVE_DATA_LEAK}/:threatId`,
    element: <SensitiveDataLeakDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.DOMAIN_TAKEOVER}/:threatId`,
    element: <DomainTakeoverDetails />
  },
  {
    path: `threats/${ThreatTypeRoute.TLS_CERTIFICATE}/:threatId`,
    element: <TLSCertificateThreatDetails />
  }
];
