import APIClient from 'src/lib/services/APIService';
import { threatStatus } from 'src/models/threat';

interface useManageThreatReturnValue {
  updateThreat: (
    threatId: number,
    body: { threatStatus: threatStatus }
  ) => void;
}
export function useManageThreat(): useManageThreatReturnValue {
  const updateThreat = (
    threatId: number,
    body: { threatStatus: threatStatus }
  ) => {
    // refresh site after successful update
    APIClient.updateThreat(threatId, body).then(() => window.location.reload());
  };

  return { updateThreat };
}
