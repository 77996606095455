import { useState } from 'react';
import APIClient from 'src/lib/services/APIService';

interface useManageAssetReturnValue {
  deleteAssets: (assetsToDelete: any[]) => void;
  addAssetsToMonitored: (assetIds: number[]) => void;
  loading: boolean;
  error: string;
}

export function useManageAsset(
  refreshOnSuccess = false
): useManageAssetReturnValue {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const deleteAssets = (assetsToDelete: any[]) => {
    setLoading(true);
    setError('');
    APIClient.deleteAssets(assetsToDelete)
      .then(() => {
        if (refreshOnSuccess) window.location.reload();
      })
      .catch((err) =>
        setError('There was an error while trying to delete asset')
      )
      .finally(() => setLoading(false));
  };

  const addAssetsToMonitored = (assetIds: number[]) => {
    setLoading(true);
    setError('');
    const data = assetIds.map((assetId) => ({
      assetId,
      processingIgnored: false
    }));
    APIClient.addAssetsToMonitored(data)
      .then(() => {
        if (refreshOnSuccess) window.location.reload();
      })
      .catch((err) => setError('There was an error while trying to scan asset'))
      .finally(() => setLoading(false));
  };

  return { loading, error, deleteAssets, addAssetsToMonitored };
}
