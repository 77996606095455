import { useEffect, useState } from 'react';
import { OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBar({
  handleChange,
  value,
  placeholder = 'Search...',
  className,
  sx
}: {
  handleChange: (newText: string) => void;
  value: string;
  placeholder?: string;
  className?;
  sx?;
}) {
  const onChange = (event) => {
    // setInterval;
  };

  const [innerValue, setInnerValue] = useState('');

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleChange(innerValue);
    }, 350);

    return () => clearTimeout(timeoutId);
  }, [innerValue]);

  return (
    <>
      <OutlinedInput
        className={className}
        sx={sx}
        fullWidth
        type="text"
        value={innerValue}
        placeholder={placeholder}
        onChange={(event) => setInnerValue(event.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton size="small">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}
