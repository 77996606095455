import { useEffect, useState } from 'react';
import APIClient from 'src/lib/services/APIService';

interface useFetchCountsReturnValue {
  monitored: number;
  suggested: number;
}

export function useFetchAssetMonitoredCounts(): useFetchCountsReturnValue {
  const [counts, setCounts] = useState({} as useFetchCountsReturnValue);

  useEffect(() => {
    APIClient.getAssetsCounts().then((resp) => setCounts(resp.data));
  }, []);

  return counts;
}
