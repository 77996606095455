import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import { BreadcrumbProvider } from './contexts/BreadcrumbContext';

import { ErrorBoundary } from './content/Error/ErrorBoundary';
import './index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, useErrorBoundary: true }
  }
});

ReactDOM.render(
  <HelmetProvider>
    <ErrorBoundary>
      <SidebarProvider>
        <BrowserRouter>
          <BreadcrumbProvider>
            <QueryClientProvider client={queryClient}>
              <App />
              {/* <ReactQueryDevtools /> */}
            </QueryClientProvider>
          </BreadcrumbProvider>
        </BrowserRouter>
      </SidebarProvider>
    </ErrorBoundary>
  </HelmetProvider>,
  document.getElementById('root')
);
