import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import LazyLoader from 'src/components/LazyLoader';
import SidebarLayout from 'src/layouts/SidebarLayout';

import Scans from 'src/content/Scans';
import { assetRoutes } from './assets';
import { threatsRoutes } from './threats';
const Dashboard = LazyLoader(lazy(() => import('src/content/Dashboard')));

const History = LazyLoader(lazy(() => import('src/content/History')));

const IntelDashboard = LazyLoader(lazy(() => import('src/content/Intel')));

const Settings = LazyLoader(lazy(() => import('src/content/Settings')));

const Graph = LazyLoader(lazy(() => import('src/content/Graph')));

const URLScanDetails = LazyLoader(
  lazy(() => import('src/content/Scans/ScanDetails'))
);

export const protectedRoutes = [
  {
    path: '/',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },

      {
        path: 'history',
        element: <History />
      },
      {
        path: 'graph',
        element: <Graph />
      },

      {
        path: 'scans',
        element: <Scans />
      },
      {
        path: 'scans/:scanId',
        element: <URLScanDetails />
      },
      {
        path: 'intel',
        element: <IntelDashboard />
      },
      {
        path: 'settings',
        element: <Settings />
      },
      ...threatsRoutes,
      ...assetRoutes
    ]
  }
];
