import { AssetTypes } from 'src/lib/constants';
import {
  HistoricTotalCounts,
  HistoryCombinedScanCount,
  HistoryScanCount,
  HistoryTimelineEvent,
  HistoryTimelineEvents
} from 'src/models/history';
import { PaginationResponse } from 'src/models/pagination';
import { APIClient } from '../APIService';

export const getAssetDetailsHistory = (
  assetType: AssetTypes,
  assetId: number,
  params = {}
) => {
  return APIClient.get(`history/${assetType}/${assetId}/`, { params }).then(
    (r) => r.data
  );
};

export const getHistoryPorts = (ipAddressId, params) => {
  return APIClient.get(`history/ip_address/${ipAddressId}/port`, {
    params
  }).then((r) => r.data);
};

export const getAssetDetailsScans = (
  assetType: AssetTypes,
  assetId: number,
  params = {}
) => {
  return APIClient.get<HistoryScanCount[]>(
    `history/${assetType}/${assetId}/scans/`,
    {
      params
    }
  ).then((r) => r.data);
};

export const getAssetHistoryTimeline = (
  assetType: AssetTypes,
  assetId: number,
  params = {}
) => {
  return APIClient.get<HistoryTimelineEvents>(
    `history/${assetType}/${assetId}/timeline/`,
    {
      params
    }
  ).then((r) => r.data);
};

export const getHistoryAllScans = (params = {}) => {
  return APIClient.get<HistoryCombinedScanCount>('history/scans/', {
    params
  }).then((r) => r.data);
};

export const getHistoryAllTimeline = (params = {}) => {
  return APIClient.get<PaginationResponse<HistoryTimelineEvent>>(
    'history/timeline/',
    { params }
  ).then((r) => r.data.results);
};

export const getHistoryTotalCounts = () => {
  return APIClient.get<HistoricTotalCounts>('history/counts/stats/').then(
    (r) => r.data
  );
};
